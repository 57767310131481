import {Component, Input} from '@angular/core';

@Component({
  selector: 'ai-assistant',
  templateUrl: './assistant.component.html',
  styleUrl: './assistant.component.scss'
})
export class AssistantComponent {

  visible: boolean = false;


  close() {
  }

  open() {
    this.visible = true;
  }
}
