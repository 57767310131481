<div [ngClass]="!visible?'display-none':''" class="assistant-backdrop" (click)="visible = false">

</div>

<div
  class="assistant-holder"
  [class.assistant-open]="visible">
  @if (visible) {
    <app-ai-assistant (closeSidebar)="visible = false"></app-ai-assistant>
  }
</div>
<span ngbTooltip="Ctrl+b" placement="bottom" class="ai-button" [class.display-none]="visible" (click)="visible = true">
  <img src="assets/media/icons/ai.svg" width="24" height="24">
  <span>Atwork AI</span>
</span>
